<script>
import UpdateVue from '@/modules/appointment/update.vue'
import clueServices from '@/services/clueServices'
export default {
  components:{
    UpdateVue
  },
  data() {
    return {
      show: false,
      formData: {}
    }
  },
  created() {
    this.getClueDetail()
  },
  methods:{
    // 提交
    confirm(params={}){
      this.onClose()
    },
    // 取消
    onClose(){
      this.show = false
    },
    getClueDetail() {
      const data = { 
        id: '1711940183567015938',
        appointmentId: '1154109873911877632',
      }
      clueServices.getClueDetail(data).then(res=>{
        if (res.appointments && res.appointments.length){
          this.formData = res.appointments[0] // 测试
        }
      })
    },
  }
}
</script>
<template>
  <div>
    <v-button @click="show = true">排程</v-button>
    <van-popup v-model="show" style="width:100%;max-width: 500px;height:100%">
      <UpdateVue v-if="show" :data="formData" @confirm="confirm" @close="onClose"/>
    </van-popup>
  </div>
  
</template>
